























































































































import { Component, Vue } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

import apiTickets from '@/api/apiTickets'
import Sent from '@/components/Sent.vue'
import ValidateService from '@/services/ValidateService'

@Component({
  components: {
    Sent,
  },
  directives: {
    mask,
  },
})
export default class Form extends Vue {
  isSanded = false

  form = {
    name: '',
    email: '',
    card: '',
    comment: '',
    confirm: false,
  }

  error = {
    name: false,
    email: false,
    card: false,
    confirm: false,
  }

  send(): void {
    this.clearErrors()

    if (!ValidateService.name(this.form.name)) {
      this.error.name = true
    }

    if (!ValidateService.email(this.form.email)) {
      this.error.email = true
    }

    if (this.form.card.length !== 19) {
      this.error.card = true
    }

    if (!this.form.confirm) {
      this.error.confirm = true
    }

    if (!this.error.card && !this.error.email && !this.error.confirm && !this.error.name) {
      this.createTicket()
    }
  }

  clearErrors(): void {
    this.error.name = false
    this.error.email = false
    this.error.confirm = false
    this.error.card = false
  }

  reset(): void {
    this.isSanded = false
    this.clearErrors()

    this.form.name = ''
    this.form.email = ''
    this.form.card = ''
    this.form.comment = ''
    this.form.confirm = false
  }

  async createTicket(): Promise<void> {
    try {
      await apiTickets.create({
        domain: window.location.host,
        name: this.form.name,
        email: this.form.email,
        card: this.form.card,
        message: this.form.comment,
      })
    } finally {
      this.isSanded = true
    }
  }
}
