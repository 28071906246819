import { AxiosResponse } from 'axios'

import Http from '@/api/Http'
import { Ticket } from '@/types/Tickets'

class ApiTickets {
  create(data: Ticket): Promise<AxiosResponse> {
    return Http.post<Ticket>('ticket/psp/create', data)
  }
}

export default new ApiTickets()
