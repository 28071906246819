import vendorConfig from '@/vendorConfig'

class ZendeskService {
  init(): void {
    const script = document.createElement('script')
    const scriptSrc = vendorConfig.zendesk.script

    script.setAttribute('id', 'ze-snippet')
    script.setAttribute('src', scriptSrc)
    script.onload = this.setup
    document.head.appendChild(script)
  }

  setup(): void {
    window.zE('webWidget', 'setLocale', 'en')
  }

  openChat(): void {
    window.zE('webWidget', 'open')
  }
}

export default new ZendeskService()
