





















import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component
export default class Sent extends Vue {
  @Prop() email: string

  @Emit('back')
  back(): void {}
}
