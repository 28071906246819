






import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Header extends Vue {
  get logo(): string {
    return window.location.host.replace('.com', '')
  }

  mounted(): void {
    document.title = this.logo
  }
}
