class ValidateService {
  name(value: string): boolean {
    // eslint-disable-next-line
    return /^[a-zA-Z'\-]+([\s][a-zA-Z]+){0,3}$/.test(value)
  }

  email(value: string): boolean {
    // eslint-disable-next-line
    return /^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
      value,
    )
  }
}

export default new ValidateService()
