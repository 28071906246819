

















import { Component, Vue } from 'vue-property-decorator'

import ZendeskService from '@/services/ZendeskService'

import Form from './components/Form.vue'
import Header from './components/Header.vue'
import Info from './components/Info.vue'

@Component({
  components: {
    Header,
    Info,
    Form,
  },
})
export default class App extends Vue {
  async created(): Promise<void> {
    await ZendeskService.init()
  }

  openSupportChat(): void {
    ZendeskService.openChat()
  }
}
