import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

class Http {
  post<T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return axios.post(`${this.domain}${url}`, data, config)
  }

  get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    return axios.get(`${this.domain}${url}`, config)
  }

  get domain(): string {
    return `${window.location.origin}/api/`
  }
}

export default new Http()
